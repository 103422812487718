<template>
  <div class="parents_div">
    <div class="main_div">
      <v-btn class="v_btn" text color="white" large @click="pushHome()">
        <span class="px-2"> Home</span>
      </v-btn>

      <div class="text-center">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="v_btn"
              text
              color="white"
              large
              v-bind="attrs"
              v-on="on"
            >
              Products <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item style="cursor:pointer" link to="/product">
              <v-list-item-content>
                <v-list-item-title>HI-SHARP</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              style="cursor:pointer"
              link
              to="/product?type=hd_camera"
            >
              <v-list-item-content>
                <v-list-item-title>HD Camera</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              style="cursor:pointer"
              link
              to="/product?type=ip_camera"
            >
              <v-list-item-content>
                <v-list-item-title>IP Camera</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:pointer" link to="/product?type=nvr">
              <v-list-item-content>
                <v-list-item-title
                  >Network Video Recoder (NVR)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:pointer" link to="/product?type=dvr">
              <v-list-item-content>
                <v-list-item-title
                  >Digital Video Recoder (DVR)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-btn class="v_btn" text color="white" large>
        <span class="px-2"> Solutions</span>
      </v-btn>
      <v-btn class="v_btn" text color="white" large>
        <span class="px-2"> Support</span>
      </v-btn>
      <v-btn class="v_btn" text color="white" large link to="/contactus">
        <span class="px-2"> Contact</span>
      </v-btn>

      <div class="text-center">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="v_btn"
              text
              color="white"
              large
              v-bind="attrs"
              v-on="on"
            >
              About <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <!-- <v-list-item style="cursor:pointer" link to="/about/ceo-message">
              <v-list-item-content>
                <v-list-item-title>CEO Message</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item
              style="cursor:pointer"
              link
              to="/about/company-profile"
            >
              <v-list-item-content>
                <v-list-item-title>Company Overview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item style="cursor:pointer" link to="/about/why-wavesine">
              <v-list-item-content>
                <v-list-item-title>Why Wavesine</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              style="cursor:pointer"
              link
              to="/about/our-achievement"
            >
              <v-list-item-content>
                <v-list-item-title>Our Achivment</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="humbargerMenu">
        <v-icon v-if="!showResponsive" color="white" @click="menu"
          >mdi-menu</v-icon
        >
        <v-icon v-else color="white" @click="menu">mdi-close</v-icon>
      </div>

      <div v-if="showResponsive" class="responsiveNav pb-16">
        <div class="d-flex">
          <v-btn
            class="navItem_s"
            @click="
              menu();
              pushHome();
            "
          >
            HOME
          </v-btn>
        </div>

        <div class="d-flex">
          <v-menu open-on-hover bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="navItem_s"
                text
                color="white"
                large
                v-bind="attrs"
                v-on="on"
              >
                Products <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                style="cursor:pointer"
                link
                to="/product?type=hd_camera"
              >
                <v-list-item-content>
                  <v-list-item-title @click="menu">HD Camera</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="cursor:pointer"
                link
                to="/product?type=ip_camera"
              >
                <v-list-item-content>
                  <v-list-item-title @click="menu">IP Camera</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor:pointer" link to="/product?type=nvr">
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >Network Video Recoder (NVR)</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor:pointer" link to="/product?type=dvr">
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >Digital Video Recoder (DVR)</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex">
          <v-btn class="navItem_s" @click="menu">
            Solutions
          </v-btn>
        </div>
        <div class="d-flex">
          <v-btn class="navItem_s" @click="menu">
            Support
          </v-btn>
        </div>
        <div class="d-flex">
          <v-btn class="navItem_s" @click="menu" link to="/contactus">
            Contact
          </v-btn>
        </div>

        <div class="d-flex">
          <v-menu open-on-hover bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="navItem_s"
                text
                color="white"
                large
                v-bind="attrs"
                v-on="on"
              >
                About <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item style="cursor:pointer" link to="/about/ceo-message">
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >CEO Message</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="cursor:pointer"
                link
                to="/about/company-profile"
              >
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >Company Profile</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="cursor:pointer" link to="/about/why-wavesine">
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >Why Wavesine</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="cursor:pointer"
                link
                to="/about/our-achievement"
              >
                <v-list-item-content>
                  <v-list-item-title @click="menu"
                    >Our Achivment</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      { title: "HD Camera" },
      { title: "IP Camera" },
      { title: "Network Video Recoder (NVR)" },
      { title: "Digital Video Recoder (DVR)" },
    ],
    isShowResponsive: true,
    showResponsive: false,
  }),
  methods: {
    menu() {
      if (this.showResponsive == true) {
        this.showResponsive = false;
      } else {
        this.showResponsive = true;
      }
    },
    pushHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.main_div {
  width: 100%;
  background-color: #2b588f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large !important;
  position: relative;
  /* position: sticky !important;
  top: 0 !important;
  z-index: 3; */
}
.parents_div {
  overflow: visible !important;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 5;
}
.humbargerMenu {
  display: none;
}
.responsiveNav {
  display: none;
}

@media screen and (max-width: 992px) {
  .v_btn {
    max-width: 110px !important;
  }
}

@media screen and (max-width: 668px) {
  .v_btn {
    display: none;
  }
  .main_div {
    padding: 16px;
  }
  .humbargerMenu {
    display: block;
    position: relative;
    background-color: #2b588f !important;
  }
  .responsiveNav {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 57px;
    left: 0;
    background-color: #373e4c;
    z-index: 3;
  }
  .v-application .pb-16 {
    padding-bottom: 0px !important;
  }
  .navItem_s {
    background-color: #373e4c !important;
    color: white !important;
    box-shadow: none !important;
  }
  .main_div {
    justify-content: end;
  }
}
</style>
