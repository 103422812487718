<template>
  <div class="">
    <div class="footer pb-16 foot">
      <v-container>
        <v-row class="pt-6 pv-16">
          <v-col md="3" sm="12">
            <h3 color="text">About</h3>
            <p class="pt-5">
              Looking into the future, WAVE SINE endeavors to utilize its
              extensive experiences in security, and continue searching the
              global standard Technologies Brand to meet our customers to
              fulfill their multiple application projects. The confidence in
              being the "Pioneer of the era and innovator for the future",
              therefore, is leading WAVE SINE to continuous growth and
              prosperity.
            </p>
          </v-col>
          <v-col md="2" sm="6">
            <h3 color="text">Contact</h3>
            <p class="pt-5">
              Address: 308 Samir Tower, Elephant Road, New Market, Dhaka 1205.
              <br />E: info@wavesine.com <br />T: +880 244 612 808 <br />
              P: +88019 78 72 41 42 <br />
              S: +88014 04 03 01 40 <br />
              S: +88014 04 03 01 41
            </p>
          </v-col>
          <v-col md="2" sm="6">
            <h3 color="text">Operating Regions</h3>
            <p class="pt-5">
              Founded in Bangladesh in 2011, WAVE SINE has been acknowledged as
              one of the leading companies in the IT and Surveillance product
              and solutions field for years.
            </p>
          </v-col>
          <v-col md="2" sm="6">
            <h3 color="text">Quick Links</h3>
            <div class="pt-5">
              <ul class="liststyle">
                <li>Privacy Policy</li>
                <li>T&Cs</li>
                <li>FAQs</li>
                <li>Site</li>
                <li>Map</li>
              </ul>
            </div>
          </v-col>
          <v-col md="3" sm="12">
            <h3 color="text">Get Social</h3>
            <p class="pt-5">
              Follow us on social media and keep in touch with Conservation
              Club.
            </p>
            <div>
              <v-icon class="mr-2 mouse">mdi-instagram</v-icon>
              <a
                href="https://www.facebook.com/wavesinebd"
                target="_blank"
                rel="noopener noreferrer"
                class="mouse"
              >
                <v-icon class="mr-2 mouse">mdi-facebook </v-icon></a
              >
              <v-icon class="mouse">mdi-linkedin</v-icon>
            </div>
            <div class="mt-4">
              <v-btn class="button red white--text" width="100%"
                >SIGN UP TO OUR NEWSLETTER</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="bottomSec">
      <p class="text-center pt-3">
        <small>© Copyright Wave Sine Technology Ltd.</small>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="sass">
.liststyle
  list-style: none
  padding-left: 0
  cursor: pointer
.bottomSec
  background: #EDE9E1
  height: 50px
  width: 100%
.foot
  background: #D6D6D6
.mouse
  cursor: pointer
  text-decoration: none
</style>
