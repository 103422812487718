import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layout/AppLayout'

import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import About from '../views/About.vue'
import CeoMessage from '@/components/about/CeoMessage.vue'
import CompanyProfile from '@/components/about/CompanyProfile.vue'
import WhyWavesine from '@/components/about/WhyWavesine.vue'
import OurAchivement from '@/components/about/OurAchivement.vue'
import ContactUs from '@/components/contactUs/ContactUs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AppLayout',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'contactus',
        name: 'ContactUs',
        component: ContactUs,
      },
      {
        path: 'product',
        name: 'Product',
        component: Product,
      },
      {
        path: 'about/',
        name: 'About',
        component: About,
        children: [
          // {
          //   path: 'ceo-message',
          //   name: 'CEO Message',
          //   component: CeoMessage,
          // },
          {
            path: 'company-profile',
            name: 'CompanyProfile',
            component: CompanyProfile,
          },
          {
            path: 'why-wavesine',
            name: 'WhyWavesine',
            component: WhyWavesine,
          },
          {
            path: 'our-achievement',
            name: 'OurAchivement',
            component: OurAchivement,
          },
        ]
      },       
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
