<template>
  <div>
    <IntroVue />
    <Products />
  </div>
</template>

<script>
import Products from "@/components/body/Products";
import IntroVue from "../components/body/Intro.vue";
export default {
  components: {
    Products,
    IntroVue,
  },
};
</script>

<style></style>
