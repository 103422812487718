<template>
  <div class="main-div">
    <div class="contact-us">
      <v-card class="pt-3">
        <h1 class="text-center">Contact US</h1>
        <div class="phone">
          <v-icon color="red">mdi-phone-classic</v-icon>
          <span>+880 244612808</span>
        </div>
        <div class="phone">
          <v-icon color="red">mdi-cellphone</v-icon>
          <span>+880 1978724142</span>
        </div>
        <div class="phone">
          Sales:
          <v-icon color="red">mdi-phone</v-icon>
          <span>+880 1404030146</span>
        </div>
        <div class="phone">
          Sales:
          <v-icon color="red">mdi-phone</v-icon>
          <span>+880 1404030147</span>
        </div>
        <div class="phone">
          Support:
          <v-icon color="red">mdi-phone</v-icon>
          <span>+880 1404030140</span>
        </div>
        <div class="phone">
          Support:
          <v-icon color="red">mdi-phone</v-icon>
          <span>+880 1404030141</span>
        </div>
        <div class="email">
          <v-icon color="red">mdi-email-outline</v-icon>
          <span>Info@wavesinebd.com</span>
        </div>
        <div class="address">
          <v-icon color="red">mdi-map-marker</v-icon>
          <span>308 Samir Tower, Elephant Road, New Market, Dhaka 1205</span>
        </div>
      </v-card>
    </div>

    <div class="location-map">
      <div style="width: 100%">
        <iframe
          width="100%"
          height="282"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.2380639251915!2d90.38744161429666!3d23.738888595134462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8c0a9e815e3%3A0x575ac674b9e9c629!2s308%20New%20Elephant%20Rd%2C%20Dhaka%201205!5e0!3m2!1sen!2sbd!4v1643824169807!5m2!1sen!2sbd"
        >
          <a href="https://www.gps.ie/golf-gps/">golf gps</a>
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-div {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
  padding-top: 30px;
}
.contact-us,
.location-map {
  width: 45%;
}
.contact-us {
  height: 320px;
}
.contact-us > h1 {
  color: #2b588f;
}
h1 {
  color: #2b588f;
}
.telephone,
.phone,
.email,
.address {
  padding: 2px 10px;
}
.telephone > span,
.phone > span,
.email > span {
  cursor: pointer;
}
span {
  padding-left: 5px;
}
@media (max-width: 750px) {
  .main-div {
    width: 95%;
    flex-flow: wrap;
    flex-direction: column;
    justify-content: center;
  }
  .contact-us,
  .location-map {
    width: 98%;
    margin: 0 auto;
  }
}
</style>
