<template>
  <div class="parent_div">
    <div class="title">
      <h1>Company Overview</h1>
    </div>
    <div class="main-div">
      <div class="details">
        <p>
          Founded in Bangladesh in 2011, WAVE SINE has been acknowledged as one
          of the leading companies in the IT and Surveillance product and
          solutions field for years. Aiming to become the leader in its fields
          of expertise, WAVE SINE is committed to delivering products from the
          top global Technologies Brand and services of the highest quality.
        </p>
        <p>
          Armed with a strong RMA team, trustworthy strength with a team of
          professional and dedicated engineers devoted to the concerns of
          quality and consumer care. WAVE SINE learn from global market and
          focusing to apply in local market, leveraging its technological
          leadership to meet the increasing demand for IT and Surveillance
          applications. WAVE SINE plans to bring a full range of global standard
          Surveillance solutions to the local market and provide customers with
          Global Standard services.
        </p>
        <p>
          Looking into the future, WAVE SINE endeavors to utilize its extensive
          experiences in security, and continue searching the global standard
          Technologies Brand to meet our customers to fulfill their multiple
          application projects. The confidence in being the "Pioneer of the era
          and innovator for the future", therefore, is leading WAVE SINE to
          continuous growth and prosperity.
        </p>
        <br />
        <p class="mb-0 font-weight-bold">Managing Director</p>
        <p class="font-weight-bold">Wave Sine Technology Ltd.</p>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-div {
  width: 85%;
  margin: 0 auto;
  padding-top: 30px;
}
.details > p {
  text-align: justify;
}
.title {
  width: 85%;
  margin: 0 auto;
  padding-top: 40px;
  color: #2b588f;
}
@media (max-width: 750px) {
  .main-div {
    width: 95%;
  }
  .title {
    width: 95%;
  }
}
</style>
