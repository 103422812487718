<template>
  <div class="main_div_s">
    <div class="hisharp logo">
      <img src="@/assets/hisharp.webp" alt="" />
    </div>

    <div class="hisharp logo">
      <img src="@/assets/brac.png" alt="" />
    </div>

    <div class="hisharp logo">
      <img src="@/assets/canon.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main_div_s {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
img {
  width: 200px;
}
.logo {
  width: 200px;
  padding: 20px;
}

@media (max-width: 1904px) {
  .main_div_s {
    max-width: 1185px;
  }
}

@media (max-width: 1264px) {
  .main_div_s {
    max-width: 900px;
  }
}

@media (max-width: 960px) {
  .main_div_s {
    max-width: 600px;
    flex-direction: column;
  }
  .logo {
    margin: 0 auto;
  }
}
</style>
