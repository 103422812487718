<template>
  <div>
    <div class="main-div container">
      <div class="title-product-section">
        <h1>Latest Products</h1>
      </div>

      <div class="products">
        <div class="product" v-for="(product, PI) in getProductList" :key="PI">
          <v-card class="mx-auto" max-width="400">
            <div class="of-h">
              <v-img
                class="white--text align-end imzs"
                :src="getImgUrl(product.image)"
                v-bind:alt="product.image"
              >
                <v-card-title></v-card-title>
              </v-img>
            </div>

            <v-card-subtitle class="pb-0">
              <h2
                class="text-center text--primary text-h5 font-weight-bold"
                style="color: #4A4A4A;"
              >
                {{ product.name }}
              </h2>
            </v-card-subtitle>

            <v-card-text class="text--primary pb-0">
              <div class="text-center">{{ product.feature }}</div>

              <div>
                <h3 class="text-center pt-3">Brand : {{ product.brand }}</h3>
              </div>
            </v-card-text>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn small color="red">
                <span class="white--text">
                  <v-icon>mdi-cloud-download</v-icon> CATALOG</span
                >
              </v-btn>

              <v-btn small color="red">
                <span class="white--text">
                  <v-icon>mdi-checkbox-marked-outline</v-icon> PURCHASE INQUERY
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from "@/json/allProducts.json";
export default {
  data() {
    return {
      products: json,
    };
  },
  computed: {
    getProductList() {
      if (this.$route.query.type) {
        if (this.$route.query.type == "hd_camera") {
          return this.products.products.filter(
            (element) => element.product_type == "HD CAMERA"
          );
        } else if (this.$route.query.type == "ip_camera") {
          return this.products.products.filter(
            (element) => element.product_type == "IP CAMERA"
          );
        } else if (this.$route.query.type == "nvr") {
          return this.products.products.filter(
            (element) => element.product_type == "NVR"
          );
        } else if (this.$route.query.type == "dvr") {
          return this.products.products.filter(
            (element) => element.product_type == "DVR"
          );
        }
      }
      return this.products.products;
    },
  },
  methods: {
    getImgUrl(imgName) {
      return require("@/assets/images/" + imgName);
    },
  },
  created() {
    console.log(this.products, "products");
    console.log(this.$route.query.type, "route");
  },
};
</script>

<style scoped>
.main-div {
  width: 100% !important;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.of-h {
  width: 344px;
  height: 250px;
  overflow: hidden;
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.imzs {
  transition: 1s;
  transition-timing-function: ease-out;
}
.imzs:hover {
  transform: scale(1.2);
}
.product {
  margin: 16px;
  width: 344px;
}
.title-product-section {
  padding-bottom: 20px;
}
@media (max-width: 960px) {
  .main-div {
    justify-content: center !important;
  }
  .products {
    justify-content: center !important;
  }
}
</style>
