<template>
  <div>
    <carousel
      :per-page="1"
      :mouse-drag="false"
      :autoplay="true"
      autoplayDirection="forward"
      :loop="true"
      :paginationEnabled="false"
    >
      <slide>
        <div class="imz">
          <img src="@/assets/slider/slider_01.png" alt="" />
        </div>
      </slide>
      <slide>
        <div class="imz">
          <img src="@/assets/slider/slider_02.png" alt="" />
        </div>
      </slide>
      <slide>
        <div class="imz">
          <img src="@/assets/slider/slider_03.png" alt="" />
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style scoped>
.imz {
  height: fit-content;
  width: 100%;
}
img {
  height: 375px;
  width: 100%;
}

@media screen and (max-width: 960px) {
  img {
    height: 135px;
    width: 100%;
  }
}
</style>
