<template>
  <div class="container my-15">
    <div class="header ">
      <h1 class="text-center font-weight-bold txt-clr">
        Wave Sine Technology at a Galnce
      </h1>
      <div class="d-flex justify-center">
        <div
          class="red my-3 "
          style="height: 10px; width: 50px; border-radius: 5px;"
        ></div>
      </div>
    </div>

    <div class="cards">
      <div class="card">
        <div>
          <span><v-icon class="text-h2 red--text">mdi-crown</v-icon></span>
        </div>
        <div class="head">
          <h2 class="my-2 font-weight-bold">Litme Enterprise</h2>
        </div>
        <div class="detail">
          <span>
            LITME Enterprise is one of the leading audio visual & security
            system in Bangladesh established in 1995. During the last 23 years
            is running the business with reputation and success.</span
          >
        </div>
      </div>

      <div class="card">
        <div>
          <span
            ><v-icon class="text-h2 red--text">mdi-certificate</v-icon></span
          >
        </div>
        <div class="head">
          <h2 class="my-2 font-weight-bold">Living on New Age</h2>
        </div>
        <div class="detail">
          <span>
            We are living in the new age of Multimedia, Influenced by
            Television, Computer and Internet, More and more people are working
            with audio aids.</span
          >
        </div>
      </div>

      <div class="card">
        <div>
          <span
            ><v-icon class="text-h2 red--text">mdi-trophy-award</v-icon></span
          >
        </div>
        <div class="head">
          <h2 class="my-2 font-weight-bold">Award</h2>
        </div>
        <div class="detail">
          <span>
            LITME Enterprise received the award from BID business International
            Directions GOLD category and Bosch Gold award as well as for their
            outstanding performances.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px !important;
  padding-top: 30px;
  max-width: 1785px;
  margin-right: auto;
  margin-left: auto;
}
.cards {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: 50px;
}
.txt-clr {
  color: rgb(43, 88, 143);
  /* color: #2b588f; */
}
.card {
  width: 345px;
  text-align: center;
  color: black;
}
.font-size {
  font-size: 50px !important;
}

@media (max-width: 1904px) {
  .container {
    max-width: 1185px;
  }
}

@media (max-width: 1264px) {
  .container {
    max-width: 900px;
  }
}

@media (max-width: 960px) {
  .container {
    max-width: 600px;
    flex-direction: column;
    justify-content: center !important;
  }
  .cards {
    justify-content: center !important;
  }
}
</style>
