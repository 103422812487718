<template>
  <div>
    <div>
      <router-view />
    </div>
    <AddressLocationSection />
  </div>
</template>

<script>
import AddressLocationSection from "../components/about/AddressLocationSection.vue";
export default {
  components: {
    AddressLocationSection,
  },
};
</script>

<style></style>
