<template>
  <div class="containerx">
    <div class="head pt-15">
      <h1>Showcase</h1>
      <p class="black--text">
        Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor
        incididunt ut laboret dolore magna aliqua enim minim veniam exercitation
      </p>
    </div>
    <div class="mt-5">
      <v-row>
        <v-col md="8" sm="12">
          <div class="imgDiv">
            <img
              src="@/assets/offersection/offersection_01.png"
              class="hotelImage"
              width="100%"
              height="460px"
              style="min-width: 350px"
              alt=""
            />
            <div class="overlay"></div>
          </div>
        </v-col>
        <v-col md="4" sm="12">
          <div class="imgDiv">
            <img
              src="@/assets/offersection/offersection_02.png"
              class="hotelImage"
              width="100%"
              height="230px"
              style="min-width: 200px"
              alt=""
            />
            <div class="overlay"></div>
          </div>
          <v-row class="pt-5">
            <v-col md="6" sm="6">
              <div class="imgDiv">
                <img
                  src="@/assets/offersection/offersection_03.png"
                  class="hotelImage"
                  width="100%"
                  height="202px"
                  style="min-width: 100px"
                  alt=""
                />
                <div class="overlay"></div>
              </div>
            </v-col>
            <v-col md="6" sm="6">
              <div class="imgDiv1">
                <img
                  src="@/assets/offersection/offersection_04.png"
                  class="hotelImage"
                  width="100%"
                  height="202px"
                  style="min-width: 100px"
                  alt=""
                />
                <div class="overlay1">
                  <div class="textnew">+8 Photos</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="sass">
.containerx
  width: 100%
  background-color: #D6D6D6
  padding-right: 5%
  padding-left: 5%
  padding-bottom: 60px
.head
  padding-bottom: 20px
.title
  font-size: 28px !important
.customBtn
  border-radius: 10px
.hotelImage
  border-radius: 5px
.overlay
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  height: 98.5%
  width: 100%
  opacity: 0
  transition: .5s ease
  background-color: #937484
  border-radius: 5px
.overlay1
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0
  height: 98.5%
  width: 100%
  opacity: .6
  transition: .5s ease
  background-color: #4A121E
  border-radius: 5px
.imgDiv
  position: relative
  &:hover .overlay
    opacity: .4
.imgDiv1
  position: relative
  &:hover .overlay1
    opacity: .9
.textnew
  color: white
  font-size: 20px
  position: absolute
  top: 50%
  left: 50%
  -webkit-transform: translate(-50%, -50%)
  -ms-transform: translate(-50%, -50%)
  transform: translate(-50%, -50%)
  text-align: center
</style>
