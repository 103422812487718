<template>
  <div class="primary--text">
    <Slider />
    <intro></intro>
    <products></products>
    <AfterProduct></AfterProduct>
    <offer />
    <sponsored />
  </div>
</template>

<script>
import intro from "@/components/body/Intro.vue";
import products from "@/components/body/Products.vue";
import AfterProduct from "@/components/body/AfterProduct.vue";
import sponsored from "@/components/body/Sponsored.vue";
import offer from "@/components/body/OfferSection.vue";
import Slider from "@/components/body/Slider";
import About from "@/components/body/About";
export default {
  name: "Home",

  components: {
    intro,
    products,
    AfterProduct,
    sponsored,
    offer,
    Slider,
    About,
  },
};
</script>
