<template>
  <div>
    <div class="head">
      <TopHeader />
      <AppHeader />
    </div>
    <div>
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import TopHeader from "@/components/navigation/TopHead";
import AppHeader from "@/components/navigation/AppHeader";
import AppFooter from "@/components/footer/AppFooter";
export default {
  components: {
    TopHeader,
    AppHeader,
    AppFooter,
  },
};
</script>

<style scoped>
.head {
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
