<template>
  <div class="main-div">
    <h1 class="text-center font-title">
      WAVE SINE TECHNOLOGY LIMITED | HISHARP BANGLADESH
    </h1>
    <br />
    <p class="text-center font-weight-bold font-size">
      <b>
        We Provide all kinds of hisharp products in Bangladesh. <br />
        <br />
        HISHARP NVR, DVR, HD CCTV CAMERA, IP CCTV CAMERA, ANALOG CAMERA, AND
        OTHER PRODUCTS
      </b>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-div {
  width: 100%;
  padding: 50px;
  margin-right: auto;
  margin-left: auto;
}
.font-size {
  font-size: 18px;
}
.font-title {
  font-weight: 32px;
}
p {
  color: #242121;
  font-size: larger;
}
</style>
