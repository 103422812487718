<template>
  <div>
    <div class="main_div">
      <div class="logo">
        <a href="/"><img src="@/assets/web_logo.png" alt=""/></a>
      </div>
      <div class="spacer"></div>

      <div class="contact">
        <div>
          <span class="font-weight-bold address-top" style="font-size: 14px">
            <v-icon size="18" color="#FF3D3D">mdi-phone-classic</v-icon>
            &nbsp; +880 244612808<br />
            <v-icon size="18" color="#FF3D3D">mdi-cellphone</v-icon>
            &nbsp; +880 1978724142<br />
            <v-icon size="18" color="#FF3D3D">mdi-email</v-icon>
            &nbsp; info@wavesine.com
          </span>
        </div>
      </div>
      <div class="address">
        <div class="text-center">
          <span class="black--text address-top">
            <v-icon class="font-size" color="#FF3D3D"
              >mdi-map-marker-radius</v-icon
            >
            &nbsp; 308 Samir Tower, Elephant Road, New Market, Dhaka 1205</span
          >
        </div>
      </div>

      <div class="social">
        <div class="youtube" @click="socialBtn('youtube')">
          <v-icon class="font-size pointer" color="#FF3D3D">mdi-youtube</v-icon>
        </div>
        <div class="facebook" @click="socialBtn('facebook1')">
          <v-icon class="font-size pointer" color="#FF3D3D"
            >mdi-facebook</v-icon
          >
        </div>
        <div class="facebook" @click="socialBtn('facebook2')">
          <v-icon class="font-size pointer" color="#FF3D3D"
            >mdi-facebook</v-icon
          >
        </div>
        <div class="whatsapp" @click="socialBtn('whatsapp')">
          <v-icon class="font-size pointer" color="#FF3D3D"
            >mdi-whatsapp</v-icon
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serviceNumber: "+8801718724142",
    };
  },
  methods: {
    socialBtn(network) {
      let shareURL = "";
      switch (network) {
        case "youtube":
          shareURL = `https://www.youtube.com/channel/UCvIYvGKBbGQ2W2ud4Ov6zNg`;
          window.open(shareURL, "_blank");
          break;
        case "facebook1":
          shareURL = `https://www.facebook.com/wavesinebd`;
          window.open(shareURL, "_blank");
          break;
        case "facebook2":
          shareURL = `https://www.facebook.com/WAVE.SINE.Technology.Ltd`;
          window.open(shareURL, "_blank");
          break;
        case "whatsapp":
          shareURL = `https://api.whatsapp.com/send?phone=${this.serviceNumber}`;
          window.open(shareURL, "_blank");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.main_div {
  width: 100%;
  padding: 0 7.5%;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  background-color: #ffffff;
}
a {
  text-decoration: none;
}
.logo {
  /* padding-top: 14px; */
}
.logo > a > img {
  height: 66px;
  width: 200px;
}
.contact {
  font-size: 14px;
}
.contact,
.address,
.social {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  padding-right: 20px;
}

.youtube,
.facebook,
.whatsapp {
  padding: 5px;
  cursor: pointer;
}
@media screen and (max-width: 992px) {
  .font-size {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 768px) {
  .social {
    display: flex;
    width: fit-content;
    margin: 0 auto;
  }
  .main_div {
    justify-content: center;
  }
}
@media screen and (max-width: 460px) {
  .logo {
    padding-top: 0px;
  }
  .address-top {
    font-size: 15px;
  }
  .spacer {
    display: none;
  }
}
@media (max-width: 452px) {
  .address-top {
    font-size: 13px;
  }
}
@media screen and (max-width: 320px) {
  .logo {
    padding-top: 0px;
  }
}
</style>
